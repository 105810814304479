<template>
  <QHeader>
    <div class="bg-primary q-py-sm bd-grey bd-2-bottom">
      <QToolbar class="bg-primary flex items-center justify-between">
        <router-link
          class="logo-container anchor-text--reset cursor-pointer text-primary"
          :to="{ name: 'blog' }"
          :aria-label="$t({ id: 'navigation.home' })"
          flat
        >
          <div class="flex items-center">
            <AppLogo />
          </div>
        </router-link>

        <div
          class="hc-menu_link_container gt-sm absolute-center"
          style="width:max-content"
        >
          <div
            class="flex flex-center q-gutter-xl"
            style="padding-inline-start:0px;"
          >
            <HCButton
              v-for="(menuItem, index) of menuItems"
              :key="index"
              :href="$_.get(menuItem, 'href', undefined)"
              :to="$_.get(menuItem, 'to', undefined)"
              color="white"
              :label="menuItem.label"
              :icon="menuItem.icon"
            />
          </div>
        </div>

        <div
          v-if="socialLinks.length"
          class="gt-sm flex items-center"
        >
          <!-- <LocaleSwitchButton /> -->
          <!-- <QBtn flat round @click="$q.dark.toggle()" :icon="$q.dark.isActive ? 'uil:sun' : 'uil:moon'" color="primary" /> -->
          <div
            v-for="socialLink of socialLinks"
            :key="socialLink.color"
          >
            <QBtn
              :icon="socialLink.icon"
              :color="socialLink.color"
              :href="socialLink.href"
              target="_blank"
              size="sm"
              class="social-link"
              flat
              dense
            >
              <ActionTooltip :path="`social.${socialLink.label}`" />
            </QBtn>
          </div>
        </div>

        <!-- Mobile menu -->
        <QBtn
          class="lt-md"
          color="dark"
          flat
          round
          dense
          icon="uil:bars"
          @click="mobileDrawer = true"
        />
      </QToolbar>
    </div>

    <!-- Mobile menu -->
    <QDrawer
      v-model="mobileDrawer"
      side="right"
      overlay
      behavior="mobile"
      bordered
    >
      <div
        class="flex column justify-between q-px-sm"
        style="height:100%;"
      >
        <QBtn
          icon="uil:bars"
          flat
          class="absolute-top-right text-black q-mt-md"
          @click="mobileDrawer = false"
        />
        <div class="flex column items-center q-gutter-y-lg q-pt-md">
          <AppLogo />
          <HCButton
            v-for="(menuItem, index) of menuItems"
            :key="index"
            :href="$_.get(menuItem, 'href', undefined)"
            :to="$_.get(menuItem, 'to', undefined)"
            :label="menuItem.label"
            :icon="menuItem.icon"
            force-label-on-xs
          />
          <div
            v-if="socialLinks.length"
            class="flex items-center"
          >
            <div
              v-for="socialLink of socialLinks"
              :key="socialLink.color"
            >
              <QBtn
                :icon="socialLink.icon"
                :color="socialLink.color"
                :href="socialLink.href"
                target="_blank"
                size="md"
                class="social-link"
                flat
                dense
              >
                <ActionTooltip :path="`social.${socialLink.label}`" />
              </QBtn>
            </div>
          </div>
        </div>
      </div>
    </QDrawer>
  </QHeader>
</template>

<script>
import AppLogo from 'src/components/common/app-logo'
// import LocaleSwitchButton from 'src/components/buttons/locale-switch-button'

export default {
  components: { AppLogo/* , LocaleSwitchButton */ },
  data () {
    return {
      query: null,
      mobileDrawer: false,
    }
  },
  computed: {
    socialLinks () { return this.$_.get(this.$store.state, 'common.config.custom.social', []) },
    menuItems () {
      return [
        { href: this.$web({ name: 'searchCompanies' }), icon: 'uil:building', label: this.$t({ id: 'navigation.menu.companies' }) },
        { href: this.$web({ name: 'searchOffers' }), icon: 'uil:briefcase', label: this.$t({ id: 'navigation.menu.offers' }) },
        { href: this.$web({ name: 'media' }), icon: 'uil:images', label: this.$t({ id: 'navigation.menu.blog' }) },
        { to: { name: 'stories' }, icon: 'uil:comment', label: this.$t({ id: 'navigation.menu.stories' }) }
      ]
    }
  }
}
</script>
